import React, { PureComponent } from "react";
import { Message } from "semantic-ui-react";
import PropTypes from "prop-types";

import Field from "../Field";

class LanguageVulnerabilityField extends PureComponent {
  static propTypes = {
    classNames: PropTypes.object.isRequired,
    value: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    showVulnerabilityWarning: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    getTranslation: PropTypes.func.isRequired
  };

  render() {
    const { classNames, value, options, onChange, getTranslation, showVulnerabilityWarning } = this.props;
    const selectText = getTranslation("Select language degree of endangerment");
    const noFoundText = getTranslation("No language degree of endangerment found.");
    const label = getTranslation(
      " Language degree of endangerment (selection affects selected languages and dictionaries)"
    );
    const selectAllText = getTranslation("Select all");
    const clearAllText = getTranslation("Clear all");

    return (
      <div>
        <Field
          classNames={classNames}
          value={value}
          name="languageVulnerability"
          options={options}
          onChange={onChange}
          label={label}
          selectAllText={selectAllText}
          clearAllText={clearAllText}
          selectText={selectText}
          noFoundText={noFoundText}
        />
        {showVulnerabilityWarning ? (
          <div className={classNames.warning}>
            <Message warning>
              <Message.Header>{getTranslation("Language degree of endangerment")}</Message.Header>
              <p>
                {getTranslation(
                  "There is no languages and dictionaries with chosen language degree of endangerment. The search will be performed with all languages and dictionaries."
                )}
              </p>
            </Message>
          </div>
        ) : null}
      </div>
    );
  }
}

export default LanguageVulnerabilityField;
