const signGroupDivision = [
  {
    id: "1",
    columns: [
      {
        id: "1",
        indexesOfSignGroup: [7]
      },
      {
        id: "2",
        indexesOfSignGroup: [1]
      }
    ]
  },
  {
    id: "4",
    columns: [
      {
        id: "5",
        indexesOfSignGroup: [0]
      },
      {
        id: "4",
        indexesOfSignGroup: [6, 8]
      }
    ]
  },
  {
    id: "2",
    columns: [
      {
        id: "3",
        indexesOfSignGroup: [2, 3]
      },
      {
        id: "6",
        indexesOfSignGroup: [4, 5]
      }
    ]
  }
];

export default signGroupDivision;
