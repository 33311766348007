import base from "./base";

const dist = {
  apiUrl: "/api",
  env: "dist",
  homePath: "/"
};

export default {
  ...base,
  ...dist
};
