import React, { useContext } from "react";
import { Header, Segment } from "semantic-ui-react";
import Immutable, { fromJS } from "immutable";
import PropTypes from "prop-types";

import Translations from "components/Translation";
import TranslationContext from "Layout/TranslationContext";

import Fields from "./Fields";

const Perspective = props => {
  const getTranslation = useContext(TranslationContext);

  const { perspective, perspectives, mode } = props;
  const translations = perspective.get("translations").toJS();

  function updateTranslations(updatedTranslations) {
    props.onChange(perspective.set("translations", fromJS(updatedTranslations)));
  }

  function updateFields(updatedFields) {
    props.onChange(perspective.set("fields", fromJS(updatedFields)));
  }

  return (
    <Segment>
      <Header>
        {getTranslation("Perspective")} {perspective.get("index") + 1}
      </Header>
      <Segment>
        <Header>{getTranslation("Perspective names")}</Header>
        <Translations translations={translations} onChange={u => updateTranslations(u)} />
      </Segment>

      <Segment>
        <Header>{getTranslation("Fields")}</Header>
        <Fields
          mode={mode}
          perspective={perspective}
          perspectives={perspectives.toJS()}
          onChange={f => updateFields(f)}
        />
      </Segment>
    </Segment>
  );
};

Perspective.propTypes = {
  perspective: PropTypes.instanceOf(Immutable.Map).isRequired,
  perspectives: PropTypes.instanceOf(Immutable.List).isRequired,
  mode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const Perspectives = props => {
  const { perspectives, mode } = props;
  function updatePerspective(perspective) {
    props.onChange(
      perspectives.set(
        perspectives.findIndex(p => p.get("index") === perspective.get("index")),
        perspective
      )
    );
  }

  return (
    <div>
      {perspectives.map(p => (
        <Perspective
          key={p.get("index")}
          perspective={p}
          perspectives={perspectives}
          onChange={np => updatePerspective(np)}
          mode={mode}
        />
      ))}
    </div>
  );
};

Perspectives.propTypes = {
  perspectives: PropTypes.instanceOf(Immutable.List).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired
};

export default Perspectives;
