import apolloClient from "./apolloClient";
import auth from "./auth";
import ban from "./ban";
import blobs from "./blobs";
import cognateAnalysis from "./cognateAnalysis";
import confirm from "./confirm";
import createDictionary from "./createDictionary";
import createOrganization from "./createOrganization";
import createPerspective from "./createPerspective";
import dialeqtImport from "./dialeqtImport";
import dictImport from "./dictImport";
import dictionaryOrganizations from "./dictionaryOrganizations";
import dictionaryProperties from "./dictionaryProperties";
import distanceMap from "./distanceMap";
import fields from "./fields";
import home from "./home";
import locale from "./locale";
import markup from "./markup";
import modals from "./modals";
import perspective from "./perspective";
import perspectiveProperties from "./perspectiveProperties";
import phonemicAnalysis from "./phonemicAnalysis";
import phonology from "./phonology";
import player from "./player";
import roles from "./roles";
import saga from "./saga";
import saveDictionary from "./saveDictionary";
import search from "./search";
import snackbar from "./snackbar";
import statistics from "./statistics";
import task from "./task";
import user from "./user";

export default {
  auth,
  locale,
  task,
  user,
  snackbar,
  saga,
  player,
  markup,
  roles,
  search,
  blobs,
  dialeqtImport,
  dictImport,
  fields,
  dictionaryProperties,
  saveDictionary,
  perspective,
  perspectiveProperties,
  home,
  createDictionary,
  cognateAnalysis,
  phonemicAnalysis,
  phonology,
  apolloClient,
  statistics,
  ban,
  modals,
  createOrganization,
  dictionaryOrganizations,
  createPerspective,
  distanceMap,
  confirm
};
